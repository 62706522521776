import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PageNav, PageRoutes, Text } from '@eltoro-ui/components';
import { Layout, Loading } from 'Components';
import { useSelector } from 'react-redux';
import { TabWrapper } from 'Pages/MyProfile/components/TabWrapper';
import { TRootState } from 'types';
import { RecommendationTypeEnum } from 'enums';
import RecommendedForYou from './components/RecommendedForYou';
import Library from './components/Library';
import './styles.scss';

export const RecommendationsPage = () => {
  const [profilePages, setprofilePages] = useState<any>([]);
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const { userRecommendations } = useSelector((state: TRootState) => state.recommendationsReducer);

  const contactsData = userRecommendations?.recommendations.find(
    (recommendation: any) => recommendation.recommendation_type === RecommendationTypeEnum.AGENT
  );
  const contactFile = userRecommendations?.contact_files;
  const isAnalyzing =
    contactFile?.active >= 1 &&
    contactsData?.buyer_count === null &&
    contactsData?.seller_count === null;
  const isImporting = contactFile?.pending >= 1 && contactFile?.active === 0;
  const isEmpty =
    contactFile?.active === 0 && !contactsData?.buyer_count && !contactsData?.seller_count;

  useEffect(() => {
    setprofilePages([
      {
        path: '/recommendations',
        title: 'Recommended for You',
        exactPath: true,
        component: () => (
          <TabWrapper>
            {!userRecommendations ? (
              <Loading />
            ) : (
              <RecommendedForYou
                recommendations={userRecommendations?.recommendations}
                audiences={userRecommendations?.audiences}
                campaigns={userRecommendations?.campaigns}
                currentPage={userRecommendations.current_page}
                totalCount={userRecommendations.total_count}
                totalPages={userRecommendations.total_pages}
              />
            )}
          </TabWrapper>
        ),
      },
      {
        path: '/recommendations/library',
        title: 'Library',
        exactPath: false,
        component: () => (
          <TabWrapper>
            {!userRecommendations ? (
              <Loading />
            ) : (
              <Library
                buyersCount={contactsData?.buyer_count}
                sellersCount={contactsData?.seller_count}
                isAnalizing={isAnalyzing}
                isImporting={isImporting}
                isEmpty={isEmpty}
              />
            )}
          </TabWrapper>
        ),
      },
    ]);
  }, [userRecommendations]);

  return (
    <Layout>
      <div className="RecommendationContainer">
        <BrowserRouter>
          <nav className="RecommendationContainer__nav">
            <Text on="white" weight="bold" UNSAFE_className="title">
              Activity Alerts
            </Text>
            <Text on="white" UNSAFE_className="heading_desc">
              Beewo&#39;s Activity Alerts scan market trends that are relevant to you. Execute
              engagement on the go.
            </Text>
            <PageNav pages={profilePages} userData={userData} />
          </nav>

          <main className="RecommendationContainer__main">
            <PageRoutes pages={profilePages} />
          </main>
        </BrowserRouter>
      </div>
    </Layout>
  );
};
