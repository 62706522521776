import React from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from 'types';
import { Link } from 'react-router-dom';

interface Props {
  recommendationsCount: number;
  isListing: boolean;
  msg?: string;
  btnText?: string;
}

const Recommendations = ({ recommendationsCount, msg, btnText, isListing }: Props) => {
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  return (
    <>
      <h4>
        🎉 Hey {userData.first_name}
        {isListing ? '!' : ', we have good news!'}
      </h4>
      <p>
        {recommendationsCount > 1
          ? `We found ${recommendationsCount} recommendations tailored to your needs, try to make the most of them!`
          : msg}
      </p>
      <Link to="/recommendations" className="link">
        {btnText || (recommendationsCount > 1 ? 'See recommendations' : 'Tell me more')}
      </Link>
    </>
  );
};

export default Recommendations;
