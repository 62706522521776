import React, { useEffect, useMemo, useState } from 'react';
import { FlexBox } from '@eltoro-ui/components';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import { Pagination } from 'Components';
import {
  AudienceStatusEnum,
  RecommendationContactEnum,
  RecommendationListingEnum,
  RecommendationTypeEnum,
} from 'enums';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRecommendations } from 'Requests';
import { setRecommendations } from 'Redux/actions';
import { TRootState } from 'types';
import EmptyState from './EmptyState';
import RecommendationTile from './RecommendationTile';
import ListingTypeRecommendation from './ListingTypeRecommendation';
import DateLabel from './DateLabel';

interface Props {
  recommendations: any;
  audiences: any;
  campaigns: any;
  totalPages: number;
  totalCount: number;
  currentPage: number;
}

const RecommendedForYou = ({
  recommendations,
  audiences,
  campaigns,
  totalCount,
  totalPages,
  currentPage,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const pageNumber = queryParams.get('page');
  const [isLoading, setIsLoading] = useState(false);
  const [recommendationList, setRecommendationList] = useState(recommendations);
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const [pages, setPages] = useState<{
    totalCount: number;
    totalPages: number;
    currentPage: number;
  }>({
    totalCount,
    totalPages,
    currentPage,
  });

  useEffect(() => {
    async function getRecommendations() {
      const recommendationsResp = await getUserRecommendations(userData.id, pageNumber ?? '1');
      if (recommendationsResp.data.recommendations) {
        const { recommendations } = recommendationsResp.data;
        setRecommendationList(recommendations);
        setIsLoading(false);
      }
    }

    if (pageNumber) {
      getRecommendations();
    }
  }, [pageNumber, userData.id]);

  const getContent = (
    recommendation: {
      recommendation_type: RecommendationTypeEnum;
      name: RecommendationListingEnum;
      recommendation_id: string;
      buyer_count: number | null;
      seller_count: number | null;
      city?: string | null;
      created_at?: number | null;
      is_owner?: boolean | null;
      state?: string | null;
      street?: string | null;
      zip?: string;
      listing_id?: number;
    },
    shouldShowDateLabel: boolean,
    currentDateLabel: string
  ) => {
    const handleOwnershipUpdate = async (newOwnershipStatus: boolean) => {
      const recommendationsResp = await getUserRecommendations(userData.id);
      if (recommendationsResp) {
        dispatch(setRecommendations(recommendationsResp.data));
      }
    };
    if (recommendation.recommendation_type === RecommendationTypeEnum.AGENT) {
      const sellersCount = recommendation?.seller_count;
      const buyersCount = recommendation?.buyer_count;
      if (sellersCount && buyersCount) {
        const tiles = [
          <RecommendationTile
            key={RecommendationContactEnum.SELLER}
            kind={RecommendationContactEnum.SELLER}
            count={sellersCount}
          />,
          <RecommendationTile
            key={RecommendationContactEnum.BUYER}
            kind={RecommendationContactEnum.BUYER}
            count={buyersCount}
          />,
        ];

        if (shouldShowDateLabel) {
          tiles.unshift(<DateLabel label={currentDateLabel} />);
        }
        return tiles;
      }
      if (sellersCount && !buyersCount) {
        return (
          <>
            {shouldShowDateLabel && <DateLabel label={currentDateLabel} />}
            <RecommendationTile kind={RecommendationContactEnum.SELLER} count={sellersCount} />
          </>
        );
      }
      if (buyersCount && !sellersCount) {
        return (
          <>
            {shouldShowDateLabel && <DateLabel label={currentDateLabel} />}
            <RecommendationTile kind={RecommendationContactEnum.BUYER} count={buyersCount} />
          </>
        );
      }
    }
    if (
      recommendation.recommendation_type === RecommendationTypeEnum.LISTING ||
      recommendation.recommendation_type === RecommendationTypeEnum.SOLD_LISTING
    ) {
      return (
        <>
          {shouldShowDateLabel && <DateLabel label={currentDateLabel} />}
          <ListingTypeRecommendation
            listingName={recommendation.name}
            isOwner={recommendation.is_owner}
            hasAssociatedCampaigns={campaigns[recommendation.recommendation_id]}
            address={[
              recommendation.street ?? '',
              recommendation.city ?? '',
              recommendation.state ?? '',
              recommendation.zip ?? '',
            ]}
            creationDate={recommendation.created_at}
            isLounchCampaign={
              audiences[recommendation.recommendation_id] &&
              audiences[recommendation.recommendation_id]?.status !== AudienceStatusEnum.ERROR &&
              (recommendation.name === RecommendationListingEnum.ALERT_NEIGHBORS ||
                recommendation.name === RecommendationListingEnum.ALERT_ABOUT_SOLD)
            }
            saveAndLaunchDisable={
              (audiences[recommendation.recommendation_id] &&
                audiences[recommendation.recommendation_id]?.status ===
                  AudienceStatusEnum.PENDING &&
                (recommendation.name === RecommendationListingEnum.ALERT_NEIGHBORS ||
                  recommendation.name === RecommendationListingEnum.ALERT_ABOUT_SOLD)) ||
              !recommendation.is_owner
            }
            audienceStatus={audiences[recommendation.recommendation_id]?.status}
            recommendationId={recommendation.recommendation_id}
            audienceId={audiences[recommendation.recommendation_id]?.id}
            listingId={recommendation.listing_id}
            handleOwnershipUpdate={handleOwnershipUpdate}
          />
        </>
      );
    }
    return null;
  };
  const hasNotRecommendations = useMemo(() => {
    if (
      !recommendations?.length ||
      (recommendations[0]?.buyer_count !== undefined &&
        recommendations[0]?.seller_count !== undefined &&
        !recommendations[0]?.buyer_count &&
        !recommendations[0]?.seller_count &&
        recommendations?.length === 1)
    ) {
      return true;
    }
    return false;
  }, [recommendations]);

  const handleNextPage = () => {
    setIsLoading(true);
    setPages({
      ...pages,
      currentPage: pages.currentPage + 1,
    });
    history.push(`?page=${pages.currentPage + 1}`);
  };

  const handlePrevPage = () => {
    setIsLoading(true);
    setPages({
      ...pages,
      currentPage: pages.currentPage - 1,
    });
    if (pages.currentPage - 1 > 0) {
      history.push(`?page=${pages.currentPage - 1}`);
    } else {
      history.push('?page=1');
    }
  };

  const formatDate = (dateString: string) => {
    const date = moment(dateString);
    if (date.isSame(moment(), 'day')) {
      return 'Today';
    }
    if (date.isSame(moment().subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    }
    return date.format('DD/MM/YYYY');
  };

  let lastDateLabel = '';

  return (
    <FlexBox flexDirection="column" gap="24px">
      {hasNotRecommendations ? (
        <EmptyState />
      ) : (
        <>
          {recommendationList.map((rec: any, index: number) => {
            const currentDateLabel = formatDate(rec.created_at);

            const shouldShowDateLabel = currentDateLabel !== lastDateLabel;
            lastDateLabel = currentDateLabel;

            return (
              <React.Fragment key={index}>
                {getContent(rec, shouldShowDateLabel, currentDateLabel)}
              </React.Fragment>
            );
          })}
          {pages.totalPages > 1 && (
            <FlexBox alignSelf="flex-end">
              <Pagination
                title="recommendations"
                loading={isLoading}
                currentPage={pages?.currentPage}
                totalItems={pages?.totalCount}
                totalPages={pages?.totalPages}
                onClickPrev={handlePrevPage}
                onClickNext={handleNextPage}
                rangeOfItems={10}
              />
            </FlexBox>
          )}
        </>
      )}
    </FlexBox>
  );
};
export default RecommendedForYou;
